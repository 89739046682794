

.button span {
  z-index: 2;
  position: relative;
  transition: color 0.3s ease-in-out;
}

.button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
  z-index: 0;
}

.button:hover {
  color: black; 
  background-color: rgb(138, 138, 138); 
}

.button:hover span {
  color: black; 
}

.icon-white {
  color: white;
}
