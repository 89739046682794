.card {
  background-image: linear-gradient(163deg, #26ee83 0%, hsl(290, 87%, 58%) 100%);
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card2 {
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  border-radius: 15px;
  display: flex; 
  flex-direction: column; 
  align-items: left; 
  text-align: left; 
}

.card:hover {
  box-shadow: 0px 0px 30px 5px rgba(238, 48, 255, 0.521);
}

.card2:hover {
  transform: scale(0.97);
  border-radius: 20px;
}
